<template>
  <div>
    <div class="">
      <sgv-form :method.sync="method" :options="options">

        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-sm-6"
            label="รหัส"
            v-model="formData.code"
            :validations="[
              {text: 'required!', value: $v.formData.code.$dirty && $v.formData.code.$error}
            ]">
          </sgv-input-text>

          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-sm-6"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
            ]">
          </sgv-input-text>
        </div>

      </sgv-form>
    </div>
  </div>
</template>

<script>
import {
  DETAIL_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DESTROY_CATEGORY
} from './graph/category'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      formData: {
        code: '',
        name: '',
      },
      method: 'info',
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
    }
  },
  computed: {
    options () {
      return [
        {text: 'เพิ่ม', variant: 'success', method: 'add', func: this.createData, disabled: true},
        {text: 'แก้ไข', variant: 'warning', method: 'edit', func: this.updateData, disabled: this.method === 'add'},
        {text: 'ลบ', variant: 'danger', method: 'delete', func: this.destroyData, disabled: this.method === 'add'},
        {text: 'กลับ', variant: 'primary', func: this.closeForm, header: true, align: 'right'},
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_CATEGORY(this.templateType),
        variables: {
          procedureType: this.procedureType,
          categoryId: this.categoryId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.category)})
      .catch(this.$toasted.global.error)
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return v
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_CATEGORY(this.templateType),
        variables: {
          procedureType: this.procedureType,
          input
        }
      })
      .then(res => {
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, categoryId: res.data.createCategory.id},
          query: {...this.$route.query, redirect: this.$route.query.redirect}
        })
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_CATEGORY(this.templateType),
        variables: {
          procedureType: this.procedureType,
          categoryId: this.categoryId,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.setFormData(res.data.updateCategory)
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_CATEGORY(this.templateType),
        variables: {
          procedureType: this.procedureType,
          categoryId: this.categoryId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        this.$router.push({name: this.$route.query.redirect})
      } else {
        this.$router.go(-1)
      }
    },
  },
  created () {
    if (this.categoryId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
  }
}
</script>

<style lang="css">
</style>
