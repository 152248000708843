import gql from 'graphql-tag'


export const LIST_CATEGORY = (templateType) => gql`query LIST_CATEGORY ($procedureType: String!, $q: FilterInput) {
  categories: list${templateType}Category (procedureType: $procedureType, q: $q) {
    id type code name
  }
}`

export const DETAIL_CATEGORY = (templateType) => gql`query DETAIL_CATEGORY ($procedureType: String!, $categoryId: Int!) {
  category: detail${templateType}Category (procedureType: $procedureType, categoryId: $categoryId) {
    id type code name
  }
}`

export const CREATE_CATEGORY = (templateType) => gql`mutation CREATE_CATEGORY ($procedureType: String!, $input: ProcedureCoreCategoryInput!) {
  createCategory: create${templateType}Category (procedureType: $procedureType, input: $input) {
    id type code name
  }
}`

export const UPDATE_CATEGORY = (templateType) => gql`mutation UPDATE_CATEGORY ($procedureType: String!, $categoryId: Int!, $input: ProcedureCoreCategoryInput!) {
  updateCategory: update${templateType}Category (procedureType: $procedureType, categoryId: $categoryId, input: $input) {
    id type code name
  }
}`

export const DESTROY_CATEGORY = (templateType) => gql`mutation DESTROY_CATEGORY ($procedureType: String!, $categoryId: Int!) {
  destroyCategory: destroy${templateType}Category (procedureType: $procedureType, categoryId: $categoryId) {id}
}`
